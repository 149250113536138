<template>
  <a-row :gutter="15">
    <a-col class="w-100" :md="24">
      <CardStyleWrapper>
        <sdCards
          :border="true"
          title="Partner's users"
          caption="Partner's users"
          class="customheader"
        >
          <template #button>
            <sdButton @click="showModal" type="primary" size="small">
              <sdFeatherIcons type="plus" />
              Add New
            </sdButton>
          </template>
          <TableWrapper class="table-data-view table-responsive">
            <a-table
              :dataSource="dataSource"
              :columns="columns"
              :loading="isLoading"
            />
          </TableWrapper>
        </sdCards>
      </CardStyleWrapper>
    </a-col>
    <sdModal
      title="User's information"
      :visible="localState.visible"
      :footer="null"
      :onCancel="handleCancel"
    >
      <div class="project-modal">
        <FormValidationWrap>
          <VerticalFormStyleWrap>
            <a-form
              name="sDash_validation-form"
              ref="formRef"
              @finish="onSubmit"
              :model="formState"
              :rules="rules"
              :layout="formState.layout"
            >
              <a-row :gutter="25">
                <a-col :md="24" :xs="24">
                  <a-form-item
                    ref="firstName"
                    label="Full name"
                    name="firstName"
                  >
                    <a-input
                      v-model:value="formState.firstName"
                      placeholder="john Doe"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24">
                  <a-form-item ref="userName" label="User name" name="userName">
                    <a-input
                      :disabled="formState.editionMode"
                      v-model:value="formState.userName"
                      placeholder="User name"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24">
                  <a-form-item ref="email" label="Email" name="email">
                    <a-input
                      :disabled="formState.editionMode"
                      v-model:value="formState.email"
                      placeholder="Email"
                    />
                  </a-form-item>
                </a-col>
                <a-col v-if="!formState.editionMode" :md="12" :xs="24">
                  <a-form-item name="password" label="Password">
                    <a-input-password
                      v-model:value="formState.password"
                      :style="{ width: '100%' }"
                    />
                  </a-form-item>
                </a-col>
                <a-col v-if="!formState.editionMode" :md="12" :xs="24">
                  <a-form-item
                    name="confirmationPassword"
                    label="confirmation password"
                  >
                    <a-input-password
                      v-model:value="formState.confirmationPassword"
                      :style="{ width: '100%' }"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24">
                  <a-form-item name="fonction" label="Contact function ">
                    <a-input
                      v-model:value="formState.fonction"
                      :style="{ width: '100%' }"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="12">
                  <a-form-item label="Role" name="roles">
                    <a-select
                      :size="'small'"
                      mode="multiple"
                      v-model:value="formState.roles"
                      placeholder="Please choose user roles"
                    >
                      <a-select-option value="Admin">Admin </a-select-option>
                      <a-select-option value="Basic">Basic</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :xs="24" :md="24">
                  <a-form-item label="Note">
                    <a-textarea
                      v-model:value="formState.note"
                      :style="{ width: '100%' }"
                      placeholder="Note ..."
                      name="address"
                      class="sDash_unresizable"
                    />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="24">
                  <sdButton
                    htmlType="submit"
                    size="full"
                    type="primary"
                    key="submit"
                  >
                    <span v-if="!formState.editionMode">Add new</span>
                    <span v-if="formState.editionMode">Edit user</span>
                  </sdButton>
                </a-col>
              </a-row>
            </a-form>
          </VerticalFormStyleWrap>
        </FormValidationWrap>
      </div>
    </sdModal>
  </a-row>
</template>
<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../../forms/overview/Style";
import { TableWrapper } from "../../styled";
import { useStore } from "vuex";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { CardStyleWrapper } from "../../../view/uiElements/ui-elements-styled";
import { onBeforeRouteLeave } from "vue-router";

const columns = [
  {
    title: "Full name",
    dataIndex: "contact.firstName",
    key: "firstName",
    //sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "User name",
    dataIndex: "contact.userName",
    key: "contact.userName",
  },
  {
    title: "Email",
    dataIndex: "contact.email",
    key: "contact.email",
  },
  // {
  //   title: 'Contact number',
  //   dataIndex: 'contact.phoneNumber',
  //   key: 'contact.phoneNumber',
  // },
  // {
  //   title: 'Address',
  //   dataIndex: 'contact.address',
  //   key: 'contact.address',
  // },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const UsersPartner = defineComponent({
  name: "UsersPartner",
  components: {
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    CardStyleWrapper,
  },
  props: {
    idPartnaire: String,
  },
  setup(props) {
    const { state, dispatch } = useStore();
    const searchValue = ref("");
    const contactApi = computed(() => state.contactApi.data);
    const isLoading = computed(() => state.contactApi.loading);
    const partnerApi = computed(() => state.partnerApi.data);

    var loading = false;
    var formState = reactive({
      layout: "vertical",
      id: 0,
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmationPassword: "",
      phoneNumber: "",
      partenaireId: props.idPartnaire,
      fonction: "",
      roles: [],
    });
    const localState = reactive({
      visible: false,
      editVisible: false,
      modalType: "primary",
      url: null,
    });
    const formRef = ref();

    let validatePass = async (rules, value) => {
      if (value === "") {
        return Promise.reject("Please input the password again");
      } else if (value !== formState.password) {
        return Promise.reject("Two inputs don't match!");
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      firstName: [
        {
          required: true,
          message: "First name is required",
          trigger: "change",
        },
      ],
      userName: [
        {
          required: true,
          message: "UserName is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Mail is required",
          trigger: "change",
        },
      ],
      partenaireId: [
        {
          required: true,
          message: "Partner is required",
          trigger: "change",
        },
      ],
      roles: [
        {
          type: "array",
          required: true,
          message: "Role is required",
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: "Password is required",
          trigger: "change",
        },
      ],
      confirmationPassword: [
        { required: true, validator: validatePass, trigger: "change" },
      ],
    };

    onMounted(() => {
      let filters = {
        UserName: "",
        PartenaireId: props.idPartnaire,
      };
      dispatch("contactGetAllData", filters);
    });

    const showModal = () => {
      formState.editionMode = false;
      delete formState.id;
      formState.firstName = "";
      formState.userName = "";
      formState.email = "";
      formState.password = "";
      formState.confirmationPassword = "";
      formState.phoneNumber = "";
      formState.partenaireId = props.idPartnaire;
      formState.fonction = "";
      formState.roles = [];
      formState.note = "";
      localState.visible = true;
    };

    const handleDelete = (partnerData) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("contactApiDataDelete", {
          id: partnerData.id,
        });
      }
      return false;
    };
    const handleEdit = (contact) => {
      formState.editionMode = true;
      delete formState.password;
      delete formState.confirmationPassword;
      formState.id = contact.id;
      formState.firstName = contact.firstName;
      formState.userName = contact.userName;
      formState.fonction = contact.fonction;
      formState.email = contact.email;
      formState.phoneNumber = contact.phoneNumber;
      formState.note = contact.note;
      formState.partenaireId = contact.partenaireId;
      formState.roles = contact.roles;
      localState.visible = true;
    };

    const onHandleSearch = () => {
      dispatch("fireStoreDataSearch", searchValue.value);
    };

    const partnerList = computed(() => {
      return partnerApi.value;
    });
    const dataSource = computed(() =>
      contactApi.value && contactApi.value.length
        ? contactApi.value.map((contact, key) => {
            return {
              key: key + 1,
              contact,
              action: (
                <div class="table-actions">
                  <a-button class="edit" onClick={() => handleEdit(contact)}>
                    <sdFeatherIcons type="edit" size={14} />
                  </a-button>
                  &nbsp;&nbsp;&nbsp;
                  <a-button
                    class="delete"
                    onClick={() => handleDelete(contact)}
                  >
                    <sdFeatherIcons type="trash-2" size={14} />
                  </a-button>
                </div>
              ),
            };
          })
        : []
    );

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          if (!formState.editionMode) {
            dispatch("contactSubmitData", {
              formState,
            });
          } else {
            dispatch("contactUpdateData", {
              formState,
            });
          }
          onCancel();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const handleCancel = () => {
      onCancel();
    };

    const onCancel = () => {
      localState.visible = false;
      formState.editionMode = false;
      localState.update = {};
    };

    onBeforeRouteLeave(() => {
      dispatch("emptyUsersData");
    });
    return {
      contactApi,
      formRef,
      rules,
      onSubmit,
      isLoading,
      handleDelete,
      handleEdit,
      dataSource,
      columns,
      formState,
      localState,
      showModal,
      handleCancel,
      onHandleSearch,
      partnerList,
      loading,
    };
  },
});

export default UsersPartner;
</script>

<style>
.customheader .ant-card-extra {
  position: absolute;
  right: 10px;
}
</style>